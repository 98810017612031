<template>
  <div>
    <v-row class="mb-5" v-if="account_type === 'manager'">
      <v-col cols="12" sm="6" md="3">
        <v-card>
          <v-card-text class="d-flex align-center justify-space-between pa-4">
            <div>
              <h2 class="font-weight-semibold mb-1">
                {{
                  numberWithComma(statistic.allPayedAmount ? statistic.allPayedAmount : 0) + statistic.currencySymbol
                }}
              </h2>
              <span>الرواتب المدفوعة</span>
            </div>

            <v-icon size="30" color="primary" class="rounded-0">
              {{ icons.mdiCashMultiple }}
            </v-icon>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" md="3">
        <v-card>
          <v-card-text class="d-flex align-center justify-space-between pa-4">
            <div>
              <h2 class="font-weight-semibold mb-1">
                {{ numberWithComma(statistic.allAdditional ? statistic.allAdditional : 0) + statistic.currencySymbol }}
              </h2>
              <span>جميع الزيادات</span>
            </div>

            <v-icon size="30" color="secondary" class="rounded-0"> fa-coins </v-icon>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" md="3">
        <v-card>
          <v-card-text class="d-flex align-center justify-space-between pa-4">
            <div>
              <h2 class="font-weight-semibold mb-1">
                {{ numberWithComma(statistic.allDiscounts ? statistic.allDiscounts : 0) + statistic.currencySymbol }}
              </h2>
              <span>جميع الاستقطاعات</span>
            </div>

            <v-icon size="30" color="warning" class="rounded-0"> fa-cash-register </v-icon>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" md="3">
        <v-card>
          <v-card-text class="d-flex align-center justify-space-between pa-4">
            <div>
              <h2 class="font-weight-semibold mb-1">
                {{
                  numberWithComma(statistic.all_lectures_price ? statistic.all_lectures_price : 0) +
                  statistic.currencySymbol
                }}
              </h2>
              <span>مبالغ المحاظرات</span>
            </div>

            <v-icon size="40" color="error" class="rounded-0">
              {{ icons.mdiCash }}
            </v-icon>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="d-flex justify-center mb-2" v-if="account_type === 'manager'">
      <v-col cols="12" sm="6" md="3">
        <v-card>
          <v-card-text class="d-flex align-center justify-space-between pa-4">
            <div>
              <h2 class="font-weight-semibold mb-1">
                {{ numberWithComma(statistic.allWatch ? statistic.allWatch : 0) + statistic.currencySymbol }}
              </h2>
              <span>مبالغ المراقبات</span>
            </div>

            <v-icon size="40" color="error" class="rounded-0">
              {{ icons.mdiCash }}
            </v-icon>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-card class="primary-shade--light pa-3">
      <h1 class="text-center subtitle-4 black--text">الرواتب</h1>
      <v-row>
        <v-col cols="12">
          <v-btn
            tile
            color="warning"
            class="ml-2 mb-4 mb-md-0 mb-sm-0"
            :block="isScreenXs"
            @click="goToSalariesShowDiscountsPage"
          >
            الرواتب المخصومة<v-icon right> fa-arrow-left </v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row class="mt-5">
        <v-col md="4" cols="12">
          <div class="d-md-flex">
            <v-btn tile color="primary" class="ml-2 mb-4 mb-md-0 mb-sm-0" :block="isScreenXs" @click="goToAddPage">
              اضافة راتب شهري<v-icon right> fa-plus </v-icon>
            </v-btn>
            <!-- <v-btn tile color="primary" class="ml-2 mb-4 mb-md-0 mb-sm-0" @click="goToInvoicePage"> فتح الفاتورة
                <v-icon right> fa-plus </v-icon>
              </v-btn> -->
            <v-btn
              tile
              color="success"
              :loading="xlsxData.downloadLoading"
              :block="isScreenXs"
              @click="getAllDataAxios"
            >
              تحميل اكسل <v-icon right> fa-download </v-icon>
            </v-btn>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col md="3" cols="12">
          <v-select
            v-model="tableModifier.isDeleted"
            :items="isDeletedBillSelectItems"
            dense
            label="اختيار نوع الفاتورة"
            outlined
            item-text="text"
            item-value="value"
          ></v-select>
        </v-col>
        <v-col md="2" cols="12">
          <v-menu
            v-model="menuStartDate"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="tableModifier.start_date"
                dense
                label="من"
                outlined
                clearable
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="tableModifier.start_date" @input="menuStartDate = false"></v-date-picker>
          </v-menu>
        </v-col>
        <v-col md="2" cols="12">
          <v-menu
            v-model="menuEndDate"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="tableModifier.end_date"
                dense
                label="الى"
                outlined
                clearable
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="tableModifier.end_date" @input="menuEndDate = false"></v-date-picker>
          </v-menu>
        </v-col>
        <v-spacer></v-spacer>
        <v-col md="4" cols="12">
          <div class="d-flex flex-row">
            <v-text-field v-model="table.search" label="البحث" outlined dense @keyup.enter="search()"></v-text-field>
            <button class="search-btn" @click="search()">
              <v-icon style="font-size: 18px; color: white"> fa-search </v-icon>
            </button>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-data-table
            :headers="table.headers"
            loading-text="جاري التحميل ... الرجاء الانتظار"
            :items="table.Data"
            :options.sync="tableOptions"
            :server-items-length="table.totalData"
            :loading="table.loading"
            class="elevation-1"
            :footer-props="{
              itemsPerPageOptions: [10, 50, 100],
            }"
          >
            <template slot="item._id" slot-scope="props">
              {{ table.totalData - props.index - (tableOptions.page - 1) * tableOptions.itemsPerPage }}
            </template>
            <template v-slot:item.account_name="{ item }">
              <!-- <a @click="goToBillsPage(item._id, item.account_name)">{{ item.account_name }}</a> -->
              {{ item.account_name }}
            </template>
            <template v-slot:item.account_type="{ item }">
              <span v-if="item.account_type === 'teacher'">مدرس</span>
              <span v-else-if="item.account_type === 'driver'">سائق</span>
              <span v-else-if="item.account_type === 'lecturer'">محاظر</span>
              <span v-else>موظف</span>
            </template>
            <template v-slot:item.amount="{ item }">
              {{ numberWithComma(item.amount) }}
            </template>
            <template v-slot:item.all_lectures_price="{ item }">
              {{ numberWithComma(item.all_lectures_price) }}
            </template>
            <template v-slot:item.per_watch_price="{ item }">
              {{ numberWithComma(item.per_watch_price) }}
            </template>
            <template v-slot:item.allDiscounts="{ item }">
              {{ numberWithComma(item.allDiscounts) }}
            </template>
            <template v-slot:item.allAdditional="{ item }">
              {{ numberWithComma(item.allAdditional) }}
            </template>
            <template v-slot:item.pureMoney="{ item }">
              {{ numberWithComma(item.pureMoney) }}
            </template>
            <template v-slot:item.actions="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="warning"
                    class="ml-2"
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    @click="getDataForSingleExcel(item)"
                  >
                    fa-download
                  </v-icon>
                </template>
                <span>تحميل اكسل</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="primary" class="ml-2" v-bind="attrs" size="20" v-on="on" @click="goToEditPage(item)">
                    fa-edit
                  </v-icon>
                </template>
                <span>تعديل</span>
              </v-tooltip>
              <v-tooltip bottom v-if="!tableModifier.isDeleted">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="#FF5252" v-bind="attrs" size="20" v-on="on" @click="deleteItem(item)">
                    fa-trash
                  </v-icon>
                </template>
                <span>حذف</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card>
    <!-- add dialog -->
    <v-dialog v-model="addDialog.open" max-width="800px">
      <v-card>
        <v-card-title>
          <span class="headline">اضافة</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form v-model="addDialog.isFormValidAdd">
              <v-row>
                <v-col cols="6">
                  <v-autocomplete
                    v-model="addData.student_id"
                    :rules="rules.student_id"
                    :loading="studentLoading"
                    :items="studentsData"
                    item-text="account_name"
                    item-value="_id"
                    clearable
                    outlined
                    dense
                    label="اسم الموظف"
                    @click:clear="addData.student_id = null"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6">
                  <v-autocomplete
                    v-model="addData.service_type"
                    :rules="rules.service_type"
                    :loading="serviceLoading"
                    :items="servicesData"
                    item-text="name"
                    item-value="_id"
                    clearable
                    outlined
                    dense
                    label="نوع الخدمة"
                    @click:clear="addData.service_type = null"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="addData.salaryAmount"
                    label="المبلغ"
                    :rules="rules.salaryAmount"
                    outlined
                    required
                    @keypress="isNumber($event)"
                    @change="salaryPlusDiscountAmountChange"
                    dense
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-menu
                    v-model="menuNextPayment"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="addData.next_payment"
                        :disabled="nextPaymentDisable"
                        dense
                        label="تاريخ الدفعة القادمة"
                        outlined
                        clearable
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      >
                      </v-text-field>
                    </template>
                    <v-date-picker v-model="addData.next_payment" @input="menuNextPayment = false"> </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="addData.discountAmount"
                    label="الخصم"
                    outlined
                    required
                    @keypress="isNumber($event)"
                    dense
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="addData.salaryPlusDiscountAmount"
                    label="المبلغ الكلي بعد الخصم"
                    outlined
                    disabled
                    filled
                    required
                    @keypress="isNumber($event)"
                    dense
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="addData.paymentAmount"
                    label="المبلغ المدفوع"
                    outlined
                    required
                    @keypress="isNumber($event)"
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="addData.remainingAmount"
                    label="المبلغ المتبقي"
                    outlined
                    required
                    @keypress="isNumber($event)"
                    disabled
                    filled
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-textarea outlined rows="4" row-height="20" v-model="addData.desc" label="الملاحظة"></v-textarea>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions class="ml-3">
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="addDialog.open = false"> الغاء </v-btn>
          <v-btn
            color="primary"
            :loading="addDialog.loading"
            :disabled="!addDialog.isFormValidAdd"
            @click="addStudentBillsData"
          >
            اضافة
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- add Class dialog -->
    <!-- delete dialog -->
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="headline justify-center"> هل انت متأكد من حذف هذا الحساب ؟ </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialogDelete = false"> الغاء </v-btn>
          <v-btn color="primary white--text" :loading="deleteItemLoading" @click="deleteItemConfirm"> حذف </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- End delete dialog -->
    <!--- dialog for show info to user-->
    <v-dialog v-model="dialogData.open" max-width="500px">
      <v-toolbar :color="dialogData.color" dense></v-toolbar>
      <v-card>
        <v-card-title class="headline justify-center"> {{ dialogData.bodyText }} </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1 justify-start" text @click="dialogData.open = false"> تم </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Api from '@/api/api'
import numberWithComma from '@/constant/number'
import router from '@/router'
import { mdiCash, mdiCashMultiple } from '@mdi/js'
import XLSX from 'xlsx'

export default {
  data() {
    return {
      excelItem: {},
      account_type: null,
      // billsTypeSelected: null
      icons: {
        mdiCashMultiple,
        mdiCash,
      },

      tableModifier: {
        start_date: null,
        end_date: null,
        isDeleted: false,
      },

      menuStartDate: false,

      menuEndDate: false,

      rules: {
        student_id: [value => !!value || 'اسم الطالب مطلوب'],
        service_type: [value => !!value || 'الخدمة مطلوبة'],
        salaryAmount: [value => !!value || 'المبلغ مطلوب'],
        type: [value => !!value || 'نوع الفاتورة مطلوب'],
        // next_payment: [value => !!value || 'تاريخ الدفعة القادمة مطلوب'],
      },

      menuNextPayment: false,

      addData: {
        student_id: null,
        salaryAmount: null,
        paymentAmount: null,
        discountAmount: null,
        remainingAmount: null,
        next_payment: null,
        desc: null,
        service_type: null,
        salaryPlusDiscountAmount: null,
      },

      deleteItemLoading: false,

      content_url: null,

      editRules: {
        Rules: [
          value => !!value || 'الرمز مطلوب',
          value => (value && value.length > 5) || 'ستة احرف او ارفام على الاقل',
        ],
        ensureRules: [
          value => !!value || 'تاكيد الرمز مطلوب',
          value => (value && value.length > 5) || 'ستة احرف او ارفام على الاقل',
        ],
      },

      dialogDelete: false,

      dialogData: {
        open: false,
        color: 'primary',
        bodyText: 'test',
      },

      xlsxData: {
        list: null,
        listLoading: true,
        downloadLoading: false,
        filename: 'الرواتب',
        autoWidth: true,
        bookType: 'xlsx',
      },

      allData: [],

      isScreenXs: false,

      deletedItem: {},

      tableOptions: {},

      addDialog: {
        open: false,
        isFormValidAdd: false,
        saveLoading: false,
        loading: false,
      },

      billsTypeSelectItems: [
        { text: 'الكل', value: null },
        { text: 'المدفوعة', value: true },
        { text: 'غير المدفوعة', value: false },
      ],

      isDeletedBillSelectItems: [
        { text: 'غير محذوفة', value: false },
        { text: 'محذوفة', value: true },
      ],

      servicesData: [],

      serviceLoading: false,

      studentsData: [],

      studentLoading: false,

      nextPaymentDisable: false,

      statistic: {
        allAmount: null,
        allLecturesNumber: null,
        allPerLecturesPrice: null,
        allWatchNumber: null,
        allPerWatchPrice: null,
        allAdditional: null,
        allDiscounts: null,
        allLectures: null,
        allWatch: null,
        allPayedAmount: null,
        currency: 'IQD',
        currencySymbol: ' IQD',
      },

      table: {
        search: null,
        totalData: 0,
        Data: [],
        loading: true,
        showImageDailog: false,
        imageUrlForShow: null,

        headers: [
          {
            text: '#',
            align: 'start',
            sortable: false,
            value: '_id',
            width: 1,
          },
          {
            text: 'الاسم',
            sortable: false,
            value: 'account_name',
          },
          { text: 'المسمى الوظيفي', sortable: false, value: 'account_type' },
          { text: 'الراتب', sortable: false, value: 'amount' },
          // { text: 'عدد المحاظرات', sortable: false, value: 'lectures_number' },
          { text: 'مبلغ المحاظرات الكلي', sortable: false, value: 'all_lectures_price' },
          { text: 'ملاحظة المحاظرة', sortable: false, value: 'lucture_note' },
          { text: 'عدد المراقبات', sortable: false, value: 'watch_number' },
          { text: 'مبلغ المراقبة', sortable: false, value: 'per_watch_price' },
          { text: 'ملاحظة المراقبة', sortable: false, value: 'watch_note' },
          { text: 'الخصم', sortable: false, value: 'allDiscounts' },
          { text: 'المكافاة', sortable: false, value: 'allAdditional' },
          { text: 'المبلغ الكلي', sortable: false, value: 'pureMoney' },
          { text: 'تاريخ التسليم', sortable: false, value: 'payment_date' },
          { text: 'العمليات', value: 'actions', sortable: false },
        ],
      },
    }
  },
  watch: {
    'addData.salaryAmount': {
      handler() {
        this.salaryPlusDiscountAmountChange()
        this.paymentChange()
      },
      // deep: true,
    },
    'addData.paymentAmount': {
      handler() {
        this.salaryPlusDiscountAmountChange()
        this.paymentChange()
      },
      // deep: true,
    },
    'addData.discountAmount': {
      handler() {
        this.salaryPlusDiscountAmountChange()
        this.paymentChange()
      },
      // deep: true,
    },
    'addData.remainingAmount': {
      handler() {
        if (this.addData.remainingAmount === 0) {
          this.nextPaymentDisable = true
          this.addData.next_payment = null
        } else {
          this.nextPaymentDisable = false
        }
      },
      // deep: true,
    },
    'tableModifier.isDeleted': {
      handler() {
        this.getDataAxios()
      },
      // deep: true,
    },

    tableOptions: {
      handler() {
        this.getDataAxios()
      },
      deep: true,
    },
    '$route.query.search': {
      handler(search) {
        this.table.search = search
        this.getDataAxios(search)
      },
      deep: true,

      // immediate: true,
    },
    '$vuetify.breakpoint': {
      handler() {
        if (this.$vuetify.breakpoint.xs) {
          this.isScreenXs = true
        } else {
          this.isScreenXs = false
        }
      },
      deep: true,
    },

    'tableModifier.start_date': {
      handler() {
        this.getDataAxios()
      },
      // deep: true,
    },

    'tableModifier.end_date': {
      handler() {
        this.getDataAxios()
      },
      // deep: true,
    },
  },

  created() {
    this.getStudents()
    this.getServices()
    this.account_type = JSON.parse(localStorage.getItem('results')).account_type
  },
  methods: {
    async getDataAxios() {
      let { search } = this.table
      this.table.loading = true
      let { page, itemsPerPage } = this.tableOptions
      if (!search) {
        search = ''
      }

      if (!page) {
        page = 1
      }
      if (!itemsPerPage) {
        itemsPerPage = 10
      }

      const study_year = JSON.parse(localStorage.getItem('study_year'))

      const response = await Api.getSalaries({
        isDeleted: this.tableModifier.isDeleted,
        start_date: this.tableModifier.start_date,
        end_date: this.tableModifier.end_date,
        study_year,
        page,
        limit: itemsPerPage,
        search,
      })

      this.statistic = response.data.results.statistic

      if (response.status === 401) {
        this.$store.dispatch('submitLogout')
      } else if (response.status === 500) {
        this.table.loading = false
        this.showDialogfunction('حصلت مشكلة يرجى المحاولة مجددا', '#FF5252')
      } else {
        this.table.loading = false
        this.table.Data = response.data.results.data
        this.table.totalData = response.data.results.count
        this.content_url = response.data.content_url
      }
    },

    goToEditPage(item) {
      localStorage.setItem('salaryEditItem', JSON.stringify(item))
      router.push('salariesShow/edit')
    },

    salaryPlusDiscountAmountChange() {
      this.addData.salaryPlusDiscountAmount =
        (this.addData.salaryAmount ? this.addData.salaryAmount : 0) -
        (this.addData.discountAmount ? this.addData.discountAmount : 0)
      this.addData.salaryPlusDiscountAmount ? this.addData.salaryPlusDiscountAmount : 0
    },

    paymentChange() {
      this.addData.remainingAmount =
        (this.addData.salaryPlusDiscountAmount ? this.addData.salaryPlusDiscountAmount : 0) -
        (this.addData.paymentAmount ? this.addData.paymentAmount : 0)
      this.addData.remainingAmount ? this.addData.remainingAmount : 0
    },

    goToInvoicePage() {
      this.$router.push('invoice/4987')
    },

    showImage(image) {
      this.table.showImageDailog = true
      this.table.imageUrlForShow = image
    },

    search() {
      this.$router.replace(
        {
          query: { search: this.table.search },
        },
        () => {},
      )
    },

    async addStudentBillsData() {
      let payments = []
      const study_year = JSON.parse(localStorage.getItem('study_year'))
      if (this.addData.salaryAmount) {
        payments.push({
          amount: this.addData.salaryAmount,
          type: 'salary',
          study_year: study_year,
          next_payment: this.addData.next_payment,
          desc: this.addData.desc,
        })
      }

      if (this.addData.paymentAmount) {
        payments.push({
          amount: this.addData.paymentAmount,
          type: 'payment',
          study_year: study_year,
          next_payment: this.addData.next_payment,
          desc: this.addData.desc,
        })
      }

      if (this.addData.discountAmount) {
        payments.push({
          amount: this.addData.discountAmount,
          type: 'discount',
          study_year: study_year,
          next_payment: this.addData.next_payment,
          desc: this.addData.desc,
        })
      }
      this.addDialog.loading = true

      const response = await Api.addStudentBills({
        student_id: this.addData.student_id,
        service_type: this.addData.service_type,
        desc: this.addData.desc,
        payments,
      })

      if (response.status === 401) {
        this.addDialog.loading = false
        this.addDialog.open = false
        this.$store.dispatch('submitLogout')
      } else if (response.status === 500) {
        this.addDialog.loading = false
        this.addDialog.open = false
        this.showDialogfunction('حصلت مشكلة يرجى المحاولة مجددا', '#FF5252')
      } else {
        this.addDialog.loading = false
        this.addDialog.open = false
        this.getDataAxios()
      }
    },

    deleteItem(item) {
      this.deletedItem = { ...item }
      this.dialogDelete = true
    },

    async deleteItemConfirm() {
      this.deleteItemLoading = true

      const response = await Api.removeEmployee(this.deletedItem._id)

      if (response.status === 401) {
        this.$store.dispatch('submitLogout')
      } else if (response.status === 500) {
        this.deleteItemLoading = false
        this.showDialogfunction('حصلت مشكلة يرجى المحاولة مجددا', '#FF5252')
      } else {
        this.deleteItemLoading = false
        this.dialogDelete = false
        this.getDataAxios()
        this.showDialogfunction(response.data.results, 'primary')
      }
    },

    async getStudents() {
      this.studentLoading = true

      const response = await Api.getAllStudents()

      if (response.status === 401) {
        this.studentLoading = false
        this.$store.dispatch('submitLogout')
      } else if (response.status === 500) {
        this.studentLoading = false
        this.showDialogfunction('حصلت مشكلة يرجى المحاولة مجددا', '#FF5252')
      } else {
        this.studentLoading = false
        this.studentsData = response.data.results
      }
    },

    async getServices() {
      this.serviceLoading = true

      const response = await Api.getService('واردات طلاب')

      if (response.status === 401) {
        this.serviceLoading = false
        this.$store.dispatch('submitLogout')
      } else if (response.status === 500) {
        this.serviceLoading = false
        this.showDialogfunction('حصلت مشكلة يرجى المحاولة مجددا', '#FF5252')
      } else {
        this.serviceLoading = false
        this.servicesData = response.data.results
      }
    },

    isNumber(evt) {
      const keysAllowed = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.']
      const keyPressed = evt.key
      if (!keysAllowed.includes(keyPressed)) {
        evt.preventDefault()
      }
    },

    Edit(item) {
      this.editedItem = { ...item }
      this.EditDialog = true
    },

    async submitEdit() {
      if (this.editedItem.account_show !== this.editedItem.ensure) {
        this.showDialogfunction('تاكيد الرمز خاطئ', '#FF5252')
      } else {
        this.EditLoading = true

        const response = await Api.edit(
          this.editedItem._id,
          sha512(this.editedItem.account__show),
          this.editedItem.account__show,
        )

        if (response.status === 401) {
          this.$store.dispatch('submitLogout')
        } else if (response.status === 500) {
          this.EditDialog = false
          this.EditLoading = false
          this.showDialogfunction('حصلت مشكلة يرجى المحاولة مجددا', '#FF5252')
        } else {
          this.EditLoading = false
          this.EditDialog = false
          this.getDataAxios()
          this.showDialogfunction(response.data.results, 'primary')
        }
      }
    },

    showDialogfunction(bodyText, color) {
      this.dialogData.open = true
      this.dialogData.bodyText = bodyText
      this.dialogData.color = color
    },

    async getAllDataAxios() {
      this.xlsxData.downloadLoading = true

      let { search } = this.table
      if (!search) {
        search = ''
      }

      const study_year = JSON.parse(localStorage.getItem('study_year'))

      const response = await Api.getAllSalaries({
        isDeleted: false,
        start_date: this.tableModifier.start_date,
        end_date: this.tableModifier.end_date,
        study_year,
        search,
      })

      if (response.status === 401) {
        this.$store.dispatch('submitLogout')
      } else if (response.status === 500) {
        this.xlsxData.downloadLoading = false
        this.showDialogfunction('حصلت مشكلة يرجى المحاولة مجددا', '#FF5252')
      } else {
        this.allData = response.data.results
        this.handleDownload()
      }
    },

    goToAddPage() {
      router.push('salariesShow/add')
    },

    goToBillsPage(student_id, student_name) {
      router.push(`studentBills/showBills/student_id/${student_id}/student_name/${student_name}`)
    },

    handleDownload() {
      import('@/vendor/Export2Excel').then(excel => {
        const tHeader = [
          'الاسم',
          'المسمى الوظيفي',
          'الراتب',
          'المحاظرات',
          'مبلغ المحاظرات الكلي',
          'ملاحظة المحاظرة',
          'عدد المراقبات',
          'مبلغ المراقبة',
          'ملاحظة المراقبة',
          'الخصم',
          'المكافاة',
          'المبلغ الكلي',
          'تاريخ التسليم',
        ]
        const filterVal = [
          'account_name',
          'account_type',
          'amount',
          'classSchool',
          'all_lectures_price',
          'lucture_note',
          'watch_number',
          'per_watch_price',
          'watch_note',
          'allDiscounts',
          'allAdditional',
          'pureMoney',
          'payment_date',
        ]

        // const { list } = this
        const data = this.formatJson(filterVal, this.allData)
        excel.export_json_to_excel({
          header: tHeader,
          data,
          filename: this.xlsxData.filename,
          autoWidth: this.xlsxData.autoWidth,
          bookType: this.xlsxData.bookType,
        })
        this.xlsxData.downloadLoading = false
      })
    },

    formatJson(filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => v[j]))
    },

    goToSalariesShowDiscountsPage() {
      router.push('salariesShowDiscounts')
    },

    async getDataForSingleExcel(item) {
      const study_year = JSON.parse(localStorage.getItem('study_year'))

      const response = await Api.getSingeSalaries({
        study_year,
        isDeleted: false,
        search: null,
        salary_id: item._id,
      })

      if (response.status === 401) {
        this.$store.dispatch('submitLogout')
      } else if (response.status === 500) {
        // this.table.loading = false
        this.showDialogfunction('حصلت مشكلة يرجى المحاولة مجددا', '#FF5252')
      } else {
        this.downLoadSingleExcel(response.data.results)
      }
    },

    downLoadSingleExcel(data) {
      const workbook = XLSX.utils.book_new()
      const header = Object.keys(data[0]) // columns name

      var wscols = []
      for (var i = 0; i < header.length; i++) {
        // columns length added
        wscols.push({ wch: header[i].length + 5 })
      }
      const worksheet = XLSX.utils.json_to_sheet(data)
      worksheet['!cols'] = wscols
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Data')
      /* generate an excel file and save it */
      XLSX.writeFile(workbook, 'data.xlsx', { bookType: 'xlsx', type: 'buffer' })
    },

    numberWithComma,
  },
}
</script>

<style scoped>
.image_table {
  cursor: pointer;
}

a {
  text-decoration: none;
}

.search-btn {
  width: 49px;
  height: 40px;
  padding: 10px;
  background: #2196f3;
  font-size: 17px;
  border: 1px solid grey;
  border-right: none;
  cursor: pointer;
  line-height: 100%;
  border-radius: 7px;
}

table.v-table tbody td {
  color: black;
}
</style>
